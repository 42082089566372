<template>
  <div>
    <div class="row maindashboard-director">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="cardcontents">
          <div class="card-header">
            안전준비태세(SAFCON) 경보단계
          </div>
          <div class="card-body" style="height: 336px">
            <div class="row">
              <div class="col-4 safcon-left" style="text-align: center !important;">
                <img :src="safconImg" width="90px" :class="safconText != '무사고' ? 'safconimgOn': ''" @click="clickSafcon"/><br>
                <div :class="safconTextClass">{{ safconText }}</div>
              </div>
              <div class="col-8 safcon-right" style="text-align: center !important;">
                <div class="row">
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass1">무사고</div>
                  </div>
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass2">주의</div>
                  </div>
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass3">경계</div>
                  </div>
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass4">심각</div>
                  </div>
                  <div class="col-12 safconDate" style="text-align: center !important;">
                    {{ safconData.startDt }} ~ {{ safconData.endDt }}
                  </div>
                  <div class="col-12 safconTeam" style="text-align: center !important;">
                    <div class="row">
                      <div class="col-12">안전사고 <span class="safconbox">{{ safconData.total }}</span>건</div>
                      <div class="col-6">생산팀 A <span class="safconbox">{{ safconData.team1 }}</span>건</div>
                      <div class="col-6">생산팀 B <span class="safconbox">{{ safconData.team2 }}</span>건</div>
                      <div class="col-6">생산팀 C <span class="safconbox">{{ safconData.team3 }}</span>건</div>
                      <div class="col-6">생산팀 D <span class="safconbox">{{ safconData.team4 }}</span>건</div>
                      <div class="col-6">생산팀 E <span class="safconbox">{{ safconData.team5 }}</span>건</div>
                      <div class="col-6">생산팀 F <span class="safconbox">{{ safconData.team6 }}</span>건</div>
                      <div class="col-6">생산팀 G <span class="safconbox">{{ safconData.team7 }}</span>건</div>
                      <div class="col-6">기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;타 <span class="safconbox">{{ safconData.etc }}</span>건</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 통합개선 이행율 현황 -->
            {{$label('LBL0000943')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                label=""
                :isFirstValue="false"
                name="plantCdImpr"
                v-model="plantCdImpr"
                @datachange="datachangeImpr" />
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                type="year"
                default="today"
                name="actionCompleteRequestDate"
                @input="getImprStatus"
                v-model="actionCompleteRequestDate"
              />
            </div>
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart" 
              height="300px" 
              type="bar"
              @dataPointSelection="selectedBarImp"
              :width="imprChart.chartWidth"
              :options="imprChart.chartOptions" 
              :series="imprChart.series"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="cardcontents">
          <div class="card-header">
            관계법령 의무사항 이행현황
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCdLaw"
                :isFirstValue="true"
                label=""
                v-model="plantCdLaw"
                @datachange="getLawCheck"/>
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                :appendToBody="false"
                type="year"
                default="today"
                name="lawYear"
                v-model="lawYear"
                @datachange="getLawCheck"
              />
            </div>
          </div>
          <div class="card-header23">
            <q-chip
              v-for="(law, idx) in lawType"
              outline square
              :key="idx"
              :removable="false"
              :color="law.selected ? 'orange' : 'grey-6'"
              text-color="white"
              :icon-selected="law.selected ? 'check' : ''"
              :selected.sync="law.selected"
              :label="law.name"
              class="mainAccTypeChip"
              v-model="law.check"
              @update:selected="state => selectedLaw(state, law, idx)"
            ></q-chip>
            <div class="accTypeIcons">
              <div class="bg-red lawcheckIcon2"></div>미진행&nbsp;&nbsp;
              <div class="bg-yellow lawcheckIcon2"></div>진행&nbsp;&nbsp;
              <div class="bg-green lawcheckIcon2"></div>완료
            </div>
          </div>
          <div class="card-body" style="height: 470px">
            <c-table
              ref="gridLaw"
              :columns="gridLaw.columns"
              :data="gridLaw.data"
              gridHeight="450px"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
            >
              <template v-slot:customArea="{ props, col }">
                <template v-if="col.stype === 'check'">
                  <q-icon v-if="props.row[col.name] == 'Y'" name="check"></q-icon>
                </template>
                <template v-if="col.name === 'status'">
                  <div :class="getLawStatusIcon(props.row)" @click="openLawPop(props.row)"></div>
                </template>
              </template>
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <!-- 작업허가 현황 [S]-->
        <div ref="main-dashborad" class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            작업허가서 현황
            <div class="card-more main-header-input main-header-input2 main-header-input2-director">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              transition-prev="slide-right"
              transition-next="slide-left"
              arrows
              infinite
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="mapWorkIcons">
                    <q-expansion-item
                      dense
                      class="shadow-1 overflow-hidden"
                      style="border-radius: 10px"
                      icon=""
                      label="범례"
                      header-class="bg-primary text-white"
                      expand-icon-class="text-white"
                      expand-icon="expand_less"
                      expanded-icon="expand_more"
                      default-opened
                    >
                      <q-card>
                        <q-card-section>
                          <ul>
                            <li v-for="(icon, iconIdx) in swpIconItems"
                              :key="iconIdx"
                            >
                              <q-icon 
                                :name="icon.icon" 
                                class="text-red"
                                size="16px" />
                              {{ icon.name }}
                            </li>
                          </ul>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </div>
                  <!-- 작업허가서 -->
                  <template v-if="mapObj.activeTask===1">
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :class="item.idx === mapObj.activeMarked ? 'selected' : ''"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate * mapRate * mapObj.ratio"
                      :y="item.locationYcoordinate * mapRate * mapObj.ratio"
                      :w="40 " :h="40"
                      :grid="[20, 20]"
                      @activated="onMarkedInfo(item)"
                    >
                      <c-map-marker :isSelected="item.idx === mapObj.activeMarked" />
                      <q-menu 
                        :ref="'markedproxy' + item.idx" 
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <q-card 
                          v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                          :key="idx"
                          flat bordered 
                          @click="openWorkPermitPop(workPermit)"
                          :class="['main-work-permit-card main-work-permit-card-cursor', setStepClass(workPermit.swpStepCd)]">
                          <q-item class="main-work-permit-card-section main-work-permit-card-item">
                            <q-item-section avatar>
                              <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label>
                                <q-icon 
                                  v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCd)"
                                  :key="iconIdx"
                                  :name="icon" 
                                  class="text-red"
                                  size="25px" />
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="main-work-permit-card-section2">
                            <b>{{workPermit.workSummary}}</b>
                          </q-card-section>
                          <q-card-section class="main-work-permit-card-section1">
                            {{workPermit.workTime}}
                          </q-card-section>
                        </q-card>
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              description="사업장에 등록된 지도가 없습니다."></el-empty>
          </div>
        </div>
        <!-- 작업허가 현황 [E]-->
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: 'main-dashboard-director',
  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      mapObj: {
        activeTask: 1,
        activeMarked: 0,
        selectedWorkPermit: {
          workPermits: [],
        },
        envUrl: '',
        markInfoUrl: '',
        url: '',
        taskParam: {
          workPermitPeriod: [],
        },
        location: {
          mdmLocationId: '',
          locationName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        maps: [],
        orignMaps: [],
        slide: 0,
        height: 800,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
        loading: false,
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          title: {
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      plantCdImpr: null,
      rate: [],
      safRate: [],
      envRate: [],
      accident: {
        startYmd: '',
        endYmd: '',
      },
      rateUrl: '',
      actionCompleteRequestDates: [],
      showTooltopCnt: 0,
      showTooltop: [false,false,false,false,false,false,false,false],
      iconItems: ['school','settings_applications','build','build_circle','restore','manage_accounts','report','report_problem'],
      colorItems2: ['primary','secondary','amber-9','deep-orange','secondary','brown-5','purple','green-7',],
      ibmTaskTypeCdList: [],
      actionCompleteRequestDate: '',
      interval: null,
      safconUrl: '',
      safconImg: '',
      safconText: '',
      safconTextClass: '',
      safconTextClass1: 'safcon-circle',
      safconTextClass2: 'safcon-circle',
      safconTextClass3: 'safcon-circle',
      safconTextClass4: 'safcon-circle',
      safconData: {
        team1: 0,
        team2: 0,
        team3: 0,
        etc: 0,
        total: 0,
        imports: 0,
        startDt: '',
        endDt: '',
      },
      plantCdLaw: null,
      lawType: [
        {
          code: 'SHTC000002',
          name: '안전보건',
          check: '',
          selected: true,
        },
        {
          code: 'SHTC000001',
          name: '환경',
          check: '',
          selected: false,
        },
        // {
        //   code: 'SHTC000003',
        //   name: '소방',
        //   check: '',
        //   selected: false,
        // },
      ],
      lawCheckUrl: '',
      lawYear: '',
      selectedLawCode: 'SHTC000002',
      selectedLawName: '안전보건',
      gridLaw: {
        columns: [
          {
            name: 'targetName',
            field: 'targetName',
            //목표
            label: 'LBL0010017',
            align: 'left',
            sortable: false,
          },
          {
            name: 'month1',
            field: 'month1',
            stype: 'check',
            //1월
            label: 'LBL0010023',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month2',
            field: 'month2',
            stype: 'check',
            //2월
            label: 'LBL0010024',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month3',
            field: 'month3',
            stype: 'check',
            //3월
            label: 'LBL0010025',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month4',
            field: 'month4',
            stype: 'check',
            //4월
            label: 'LBL0010026',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month5',
            field: 'month5',
            stype: 'check',
            //5월
            label: 'LBL0010027',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month6',
            field: 'month6',
            stype: 'check',
            //6월
            label: 'LBL0010028',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month7',
            field: 'month7',
            stype: 'check',
            //7월
            label: 'LBL0010029',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month8',
            field: 'month8',
            stype: 'check',
            //8월
            label: 'LBL0010030',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month9',
            field: 'month9',
            stype: 'check',
            //9월
            label: 'LBL0010031',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month10',
            field: 'month10',
            stype: 'check',
            //10월
            label: 'LBL0010032',
            align: 'center',
            style: 'width:36px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month11',
            field: 'month11',
            stype: 'check',
            //11월
            label: 'LBL0010033',
            align: 'center',
            style: 'width:36px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month12',
            field: 'month12',
            stype: 'check',
            //12월
            label: 'LBL0010034',
            align: 'center',
            style: 'width:36px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'status',
            field: 'status',
            label: '여부',
            align: 'center',
            style: 'width: 36px',
            sortable: false,
            type: 'custom',
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    // window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  activated() {
    this.getMapData();
  },
  deactivated() {
    // window.removeEventListener('resize', this.setSize);
    // clearInterval(this.mapObj.interval.obj);
    // clearInterval(this.interval);
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setSize);
    // clearInterval(this.mapObj.interval.obj);
    // clearInterval(this.interval);
  },
  computed: {
    colorItems() {
      return [
        // 계획수립
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$comm.getLangLabel('LBL0000381'), colorClass: 'blue' },
        // 점검중
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$comm.getLangLabel('LBL0000382'), colorClass: 'orange' },
        // 점검완료
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$comm.getLangLabel('LBL0000383'), colorClass: 'green' },
        // 지연
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$comm.getLangLabel('LBL0000384'), colorClass: 'red' },
      ]
    },
    swpIconItems() {
      return [
        {
          icon: 'engineering',
          name: '일반',
        },
        {
          icon: 'local_fire_department',
          name: '화기',
        },
        {
          icon: 'dangerous',
          name: '밀폐공간',
        },
        {
          icon: 'flash_off',
          name: '정전',
        },
        {
          icon: 'hardware',
          name: '굴착',
        },
        {
          icon: 'stairs',
          name: '고소',
        },
        {
          icon: 'warning',
          name: '방사선',
        },
        {
          icon: 'agriculture',
          name: '중장비',
        },
      ];
    }, 
    mapRate() {
      return 0.870
    },
  },
  methods: {
    init() {
      this.showTooltopCnt = 0
      this.rateUrl = selectConfig.main.imprChart.url;
      // 안전준비태세
      this.safconUrl = selectConfig.main.safcon.url;
      this.lawCheckUrl = selectConfig.main.lawcheck.url;

      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url

      this.actionCompleteRequestDate = this.$comm.getThisYear();

      this.selectedLawCode = 'SHTC000002';
      this.selectedLawName = '안전보건';

      this.setLangLabel();
      this.getImprStatus(null);
      this.getMapPriodParam();
      this.getSafcon();
      this.getLawCheck();
    },
    getLawCheck() {
      this.$http.url = this.lawCheckUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdLaw,
        year: this.lawYear,
        safetyHealthyTypeCd: this.selectedLawCode,
      };
      this.$http.request((_result) => {
        this.gridLaw.data = _result.data
      },);
    },
    selectedLaw(state, law, idx) {
      this.selectedLawCode = law.code;
      this.selectedLawName = law.name;
      if (state) {
        this.lawType.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.lawType[idx], 'selected', true)
      }
      this.getLawCheck();
    },
    getLawStatusIcon(_row) {
      let iconColor = '';
      switch(_row.status) {
        case 'SHS0000001':
          iconColor = 'bg-red lawcheckIcon';
          break;
        case 'SHS0000005':
          iconColor = 'bg-yellow lawcheckIcon';
          break;
        case 'SHS0000010':
          iconColor = 'bg-green lawcheckIcon';
          break;
      }
      return iconColor;
    },
    openLawPop(row) {
      this.popupOptions.title = '관계법령 의무사항 사업주 이행현황 상세';  //ESF 목표 및 추진계획/실적 상세
      this.popupOptions.param = {
        mainSafetyHealthyTargetId: row ? row.mainSafetyHealthyTargetId : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/main/mainSafetyHealthyTargetDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeLawPopup;
    },
    closeLawPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setSize() {
      setTimeout(() => {
        this.getMapData(true);
      }, 300);
    },
    clickSafcon() {
      if (this.safconText != '무사고') {
        this.popupOptions.title = '주간 공정사고 검색';
        this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentMainPop.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeclickSafcon;
      }
    },
    closeclickSafcon() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getSafcon() {
      this.$http.url = this.safconUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.safconData = _result.data
        if (this.safconData.imports > 0) {
          this.safconText = '심각';
          this.safconTextClass = 'safcon-left-text text-red';
          this.safconImg = require('@/assets/images/safcon/level04.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle bg-red'
        } else if (this.safconData.total == 0) {
          this.safconText = '무사고';
          this.safconTextClass = 'safcon-left-text text-green';
          this.safconImg = require('@/assets/images/safcon/level01.png');
          this.safconTextClass1 = 'safcon-circle bg-green'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle'
        } else if (this.safconData.total <= 2) {
          this.safconText = '주의';
          this.safconTextClass = 'safcon-left-text text-yellow';
          this.safconImg = require('@/assets/images/safcon/level02.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle bg-yellow'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle'
        } else if (this.safconData.total == 3) {
          this.safconText = '경계';
          this.safconTextClass = 'safcon-left-text text-orange';
          this.safconImg = require('@/assets/images/safcon/level03.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle bg-orange'
          this.safconTextClass4 = 'safcon-circle'
        } else if (this.safconData.total >= 4) {
          this.safconText = '심각';
          this.safconTextClass = 'safcon-left-text text-red';
          this.safconImg = require('@/assets/images/safcon/level04.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle bg-red'
        }
      },);
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    getMapData(loading) {
      clearInterval(this.interval);
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          /**
           * 지도는 1개
           */
          this.$_.forEach(_result.data, item => {
            this.mapObj.endIdx = 1;
            if (item.otherMaps && item.otherMaps.length > 0) {
              this.$_.forEach(item.otherMaps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
          })

          this.mapObj.maps = this.$_.cloneDeep(_result.data)
          this.mapObj.orignRatio = this.mapObj.maps[0].ratio;
          
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
          
          this.mapObj.height = this.fullscreen ? 900 : 550
          let resizeRatio = Math.round(this.mapObj.height / (this.fullscreen ? 900 : 575) * 100) / 100;
          let _height = this.mapObj.height * this.mapRate
          this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
          this.mapObj.ratio = resizeRatio

          // 로딩바 제거
          if (loading) {
            this.mapObj.loading = false;
          }
        }
      },);
    },
    onMarkedInfo(markedInfo) {
      this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.resultTypes);
      this.$set(this.mapObj, 'activeMarked', markedInfo.idx)
    },
    /* eslint-disable no-unused-vars */
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('engineering');
              break;
            case 'SPT0000005': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐공간
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 정전
              icons.push('flash_off');
              break;
            case 'SSWT000010': // 방사선
              icons.push('warning');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 중장비
              icons.push('agriculture');
              break;
          }
        })
      }
      return icons;
    },
    openWorkPermitPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePermitPopup;
    },
    closePermitPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setLangLabel() {
      let thisVue = this;
      this.imprChart.series = [
      {
          id: 'actioning',
          name: this.$comm.getLangLabel('LBL0000944'), // 조치중
          data: [],
        },
        {
          id: 'complete',
          name: this.$comm.getLangLabel('LBL0000945'), // 개선완료(총합)
          data: [],
        },
        {
          id: 'overAction',
          name: this.$comm.getLangLabel('LBL0000934'), // 지연 건수
          data: [],
        },
      ]
      this.imprChart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    datachangeImpr() {
      this.getImprStatus();
    },
    getImprStatus() {
      let dates = [];
      if (this.actionCompleteRequestDate !== null) {
        dates = [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31'];
      }
      this.$http.url = this.rateUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdImpr,
        actionCompleteRequestDates: dates,
      }
      this.rate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          this.ibmTaskTypeCdList.push({
            code: task.ibmTaskTypeCd,
            codeName: task.ibmTaskTypeName,
          });
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
    selectedBarImp(e, chart, opts) {
      let stepGubun = '';
      switch (opts.w.config.series[opts.seriesIndex].name) {
        case '조치중':
          stepGubun = 'actioning'
          break;
        case '개선완료(총합)':
          stepGubun = 'complete'
          break;
        case '지연 건수':
          stepGubun = 'overAction'
          break;
        default:
          break;
      }
      this.popupOptions.width = '90%';
      this.popupOptions.title = "LBL0000948"; // 개선목록 (업무별 현황)
      this.popupOptions.param = {
        plantCd: this.plantCdImpr,
        ibmTaskTypeCd: this.$_.filter(this.ibmTaskTypeCdList, { codeName: this.imprChart.chartOptions.xaxis.categories[opts.dataPointIndex] })[0].code,
        actionCompleteRequestDates: [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31'],
        stepGubun: stepGubun,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/impr.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.main-work-permit-card-cursor
  cursor: pointer
.maindashboard-director 
  .fullscreen-btn
    .q-btn__wrapper
      min-width: 10px !important
      min-height: 10px !important
  .no-padding
    padding: 0px 0px 6px 0px !important
  .cardcontents
    box-shadow: 0 !important
    border: 1px solid rgba(0,0,0,0.12) !important
    border-radius: 10px !important
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
      border-radius: 0 0 10px 10px !important
      background: #fff
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      border-bottom-width: 0px !important
      border-radius: 10px 10px 0 0 !important
      height: 40px
      color: #0072c6
      background: #f8f8f9
      display: inline-block
      padding-top: 8px
      width: 100%
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394

    .card-header23
      border-bottom-width: 0px !important
      border-radius: 0 0 0 0 !important
      height: 40px
      background: #fff
      color: #0072c6
      display: inline-block
      padding-top: 8px
      width: 100%
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more-add
        float: right !important
        cursor: pointer
        color: #d2d2d2
        padding-right: 10px
      .card-more-add:hover
        color: #1ab394
      .card-more:hover
        color: #1ab394
      .inputClass
        background: #fff !important
  .main-header-input
    margin-top: -5px
    padding-right: 5px
  .main-header-input2
    margin-top: -5px
    padding-right: 5px
    width: 240px !important
    .customDate
      padding-bottom: 0 !important
      .q-field__inner
        padding: 0 !important
  .cardcontents .card-body.nopadgrid
    padding-top: 0px !important
  .btn-fixed-width-height
    width: 95%
    margin: 3px !important
    pointer-events: none

  .directorMainLayer
    .workPermit-carousel
      border-radius: 10px
      height: var(--map-height) !important
      .q-carousel__slide
        width: calc(var(--map-ratio) * var(--map-height)) !important
        max-width: calc(var(--map-ratio) * var(--map-height)) !important
        position: relative
      .q-carousel--padding, .q-carousel__slide
        padding: 0 !important
      .q-carousel__arrow
        transition: all 0.5s
        opacity: 0.2
      .q-carousel__arrow:hover
        transition: all 0.5s
        opacity: 0.8
    .caption-work2
      font-size: 0.9em
      white-space: normal
      color: #fff
      padding: 10px
    .caption-work
      font-size: 0.9em
      // font-style: italic
      white-space: normal
      color: #555
      margin-top: 4px
    .mainmapstart
      float: right
      margin-right: 0px
      margin-top: -5px
    .mainMarkImage
      padding: 0px !important
      font-size: 20px
      height: 30px !important
      position: absolute
      padding: 16px
      color: #C10015
      z-index: 3 !important
      .markImage-abbreviation-parent
        text-align: start
        position: absolute
        .markImage-abbreviation
          cursor: pointer
          display: inline-block
          width: auto
          border-left: 1px solid #757575
          border-right: 1px solid #757575
          border-top: 1px solid #757575
          border-bottom: 1px solid #757575
          border-radius: 5px
          .markImage-tran
            border-radius: 5px
          .markImage-abbreviation-banner
            border-right: 1px solid #757575
            display: inline-block
            text-align: center
            min-width:60px
            padding: 1px !important
            border-radius: 5px 0px 0px 5px
            i
              font-size: 20px
            div
              font-size: 12px !important
          .markImage-abbreviation-banner.last-banner
            border-right: 0
            border-radius: 0px 5px 5px 0px
          .markImage-abbreviation-plus
            display: inline-block
            text-align: center
            min-width:40px
          .markImage-abbreviation-banner-detail
            border-radius: 5px
            .q-banner__avatar i
              font-size: 20px !important
            .q-banner__content
              font-size: 12px !important
    .markImage-abbreviation > div
      -webkit-animation: fadeInFromNone 0.1s ease-out
      -moz-animation: fadeInFromNone 0.1s ease-out
      -o-animation: fadeInFromNone 0.1s ease-out
      animation: fadeInFromNone 0.1s ease-out
</style>
<style scoped>
.main-work-permit-card-light-blue {
  border-top: 2px solid #03a9f4;
}
.main-work-permit-card-amber {
  border-top: 2px solid #ffc107;
}
.main-work-permit-card-light-green {
  border-top: 2px solid #8bc34a;
}


.safcon-left {
  padding-top: 50px !important;
}
.safcon-left-text {
  padding-top: 20px;
  font-size: 2em;
  font-weight: 600;
}
.safcon-right {
  margin-top: 0px;
}
.safcon-circle {
  width: 54px;
  height: 54px;
  background-color: rgb(202, 200, 200);
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  padding-top:15px;
  color: #fff;
  font-weight: 600;
}
.safconDate {
  margin-top: 10px;
  padding-top:2px;
  font-size: 1.1em;
  font-weight: 600;
  border:1px solid #cccdcf;
  border-radius: 10px;
  margin-bottom: 15px;
}
.safconTeam {
  border-radius: 10px;
  background-color: rgb(23, 112, 23);
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 1.05em;
  color: #fff;
}
.safconTeam .col-12 {
  font-size: 1.05em;
  font-weight: 600;
}
.safconbox {
  padding:0px 6px;
  background-color: #161d31;
  font-weight: 600;
  text-align: center;
  color: red;
}
.safconimgOn {
  cursor: pointer;
}

.mainAccTypeChip {
  border: 0px !important;
}
.card-header23 .mainAccTypeChip:first-child {
  margin-left: 6px !important;
}
.mainAccTypeChip:focus {
  box-shadow: none !important;
}
.lawcheckIcon {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 50%;
  margin-top: 3px;
  cursor: pointer;
}
.lawcheckIcon2 {
  width: 12px;
  height: 12px;
  margin: 0 auto;
  border-radius: 50%;
  margin-top: 3px;
  cursor: pointer;
}
.accTypeIcons {
  float: right;
  display: flex;
  font-size: 0.8em !important;
  margin-right: 25px;
  padding-top: 5px;
}
.accTypeIcons > div {
  margin-right: 5px;
}

.card-header23 + .card-body {
  padding-top: 0px !important;
}
.mapWorkIcons {
  position: absolute;
  opacity: 0.65;
  /* background-color: lightgrey;   */
  /* border-radius: 10px; */
  right: -70px;
  bottom: 0px;
  padding: 5px 10px;
  font-weight: 700;
  line-height: 20px;
  z-index: 9999;
  font-size: 11px;
}
.mapWorkIcons .q-item {
  padding: 0px 10px;
}
.mapWorkIcons ul {
  list-style-type: none;
  margin-bottom: 0px !important;
}
.mapTitleDiv {
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  background-color: rgba(0, 143, 251, 0.75) !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
.main-work-permit-card-section2 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  cursor: pointer;
}
.main-work-permit-card-section1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.mapWorkIcons .q-card__section{
  padding: 4px !important;
}
</style>