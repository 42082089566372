var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row maindashboard-director" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 안전준비태세(SAFCON) 경보단계 "),
              ]),
              _c(
                "div",
                { staticClass: "card-body", staticStyle: { height: "336px" } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-4 safcon-left",
                        staticStyle: { "text-align": "center !important" },
                      },
                      [
                        _c("img", {
                          class:
                            _vm.safconText != "무사고" ? "safconimgOn" : "",
                          attrs: { src: _vm.safconImg, width: "90px" },
                          on: { click: _vm.clickSafcon },
                        }),
                        _c("br"),
                        _c("div", { class: _vm.safconTextClass }, [
                          _vm._v(_vm._s(_vm.safconText)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-8 safcon-right",
                        staticStyle: { "text-align": "center !important" },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-3",
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _c("div", { class: _vm.safconTextClass1 }, [
                                _vm._v("무사고"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-3",
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _c("div", { class: _vm.safconTextClass2 }, [
                                _vm._v("주의"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-3",
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _c("div", { class: _vm.safconTextClass3 }, [
                                _vm._v("경계"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-3",
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _c("div", { class: _vm.safconTextClass4 }, [
                                _vm._v("심각"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-12 safconDate",
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.safconData.startDt) +
                                  " ~ " +
                                  _vm._s(_vm.safconData.endDt) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-12 safconTeam",
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _vm._v("안전사고 "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.total)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 A "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team1)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 B "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team2)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 C "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team3)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 D "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team4)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 E "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team5)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 F "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team6)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("생산팀 G "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.team7)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("기     타 "),
                                  _c("span", { staticClass: "safconbox" }, [
                                    _vm._v(_vm._s(_vm.safconData.etc)),
                                  ]),
                                  _vm._v("건"),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$label("LBL0000943")) + " "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        label: "",
                        isFirstValue: false,
                        name: "plantCdImpr",
                      },
                      on: { datachange: _vm.datachangeImpr },
                      model: {
                        value: _vm.plantCdImpr,
                        callback: function ($$v) {
                          _vm.plantCdImpr = $$v
                        },
                        expression: "plantCdImpr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        type: "year",
                        default: "today",
                        name: "actionCompleteRequestDate",
                      },
                      on: { input: _vm.getImprStatus },
                      model: {
                        value: _vm.actionCompleteRequestDate,
                        callback: function ($$v) {
                          _vm.actionCompleteRequestDate = $$v
                        },
                        expression: "actionCompleteRequestDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("apexchart", {
                    ref: "imprChart",
                    attrs: {
                      height: "300px",
                      type: "bar",
                      width: _vm.imprChart.chartWidth,
                      options: _vm.imprChart.chartOptions,
                      series: _vm.imprChart.series,
                    },
                    on: { dataPointSelection: _vm.selectedBarImp },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 관계법령 의무사항 이행현황 "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        name: "plantCdLaw",
                        isFirstValue: true,
                        label: "",
                      },
                      on: { datachange: _vm.getLawCheck },
                      model: {
                        value: _vm.plantCdLaw,
                        callback: function ($$v) {
                          _vm.plantCdLaw = $$v
                        },
                        expression: "plantCdLaw",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        appendToBody: false,
                        type: "year",
                        default: "today",
                        name: "lawYear",
                      },
                      on: { datachange: _vm.getLawCheck },
                      model: {
                        value: _vm.lawYear,
                        callback: function ($$v) {
                          _vm.lawYear = $$v
                        },
                        expression: "lawYear",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-header23" },
                [
                  _vm._l(_vm.lawType, function (law, idx) {
                    return _c("q-chip", {
                      key: idx,
                      staticClass: "mainAccTypeChip",
                      attrs: {
                        outline: "",
                        square: "",
                        removable: false,
                        color: law.selected ? "orange" : "grey-6",
                        "text-color": "white",
                        "icon-selected": law.selected ? "check" : "",
                        selected: law.selected,
                        label: law.name,
                      },
                      on: {
                        "update:selected": [
                          function ($event) {
                            return _vm.$set(law, "selected", $event)
                          },
                          (state) => _vm.selectedLaw(state, law, idx),
                        ],
                      },
                      model: {
                        value: law.check,
                        callback: function ($$v) {
                          _vm.$set(law, "check", $$v)
                        },
                        expression: "law.check",
                      },
                    })
                  }),
                  _vm._m(0),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "card-body", staticStyle: { height: "470px" } },
                [
                  _c("c-table", {
                    ref: "gridLaw",
                    attrs: {
                      columns: _vm.gridLaw.columns,
                      data: _vm.gridLaw.data,
                      gridHeight: "450px",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.stype === "check"
                              ? [
                                  props.row[col.name] == "Y"
                                    ? _c("q-icon", { attrs: { name: "check" } })
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            col.name === "status"
                              ? [
                                  _c("div", {
                                    class: _vm.getLawStatusIcon(props.row),
                                    on: {
                                      click: function ($event) {
                                        return _vm.openLawPop(props.row)
                                      },
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "div",
              {
                ref: "main-dashborad",
                staticClass: "cardcontents directorMainLayer",
              },
              [
                _c(
                  "div",
                  { staticClass: "card-header non-label-icon mapheader" },
                  [
                    _vm._v(" 작업허가서 현황 "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-more main-header-input main-header-input2 main-header-input2-director",
                      },
                      [
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 1,
                              expression: "mapObj.activeTask===1",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "workPermitPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.workPermitPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "workPermitPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.workPermitPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "carousel",
                    staticClass: "card-body",
                    staticStyle: { padding: "15px !important" },
                  },
                  [
                    _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                      ? _c(
                          "q-carousel",
                          {
                            staticClass: "workPermit-carousel",
                            attrs: {
                              swipeable: "",
                              animated: "",
                              "transition-prev": "slide-right",
                              "transition-next": "slide-left",
                              arrows: "",
                              infinite: "",
                              "control-type": "regular",
                              "control-color": "grey-6",
                            },
                            model: {
                              value: _vm.mapObj.slide,
                              callback: function ($$v) {
                                _vm.$set(_vm.mapObj, "slide", $$v)
                              },
                              expression: "mapObj.slide",
                            },
                          },
                          _vm._l(_vm.mapObj.maps, function (map, idx) {
                            return _c(
                              "q-carousel-slide",
                              {
                                key: idx,
                                attrs: { name: idx, "img-src": map.mapSrc },
                              },
                              [
                                [
                                  _c("div", {
                                    staticClass: "mapTitleDiv",
                                    domProps: {
                                      textContent: _vm._s(map.mapName),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mapWorkIcons" },
                                    [
                                      _c(
                                        "q-expansion-item",
                                        {
                                          staticClass:
                                            "shadow-1 overflow-hidden",
                                          staticStyle: {
                                            "border-radius": "10px",
                                          },
                                          attrs: {
                                            dense: "",
                                            icon: "",
                                            label: "범례",
                                            "header-class":
                                              "bg-primary text-white",
                                            "expand-icon-class": "text-white",
                                            "expand-icon": "expand_less",
                                            "expanded-icon": "expand_more",
                                            "default-opened": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "q-card",
                                            [
                                              _c("q-card-section", [
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    _vm.swpIconItems,
                                                    function (icon, iconIdx) {
                                                      return _c(
                                                        "li",
                                                        { key: iconIdx },
                                                        [
                                                          _c("q-icon", {
                                                            staticClass:
                                                              "text-red",
                                                            attrs: {
                                                              name: icon.icon,
                                                              size: "16px",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                icon.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.mapObj.activeTask === 1
                                    ? _vm._l(map.maps, function (item, idx) {
                                        return _c(
                                          "VueDraggableResizable",
                                          {
                                            key: idx,
                                            ref: "markImage",
                                            refInFor: true,
                                            staticClass: "mainMarkImage",
                                            class:
                                              item.idx ===
                                              _vm.mapObj.activeMarked
                                                ? "selected"
                                                : "",
                                            attrs: {
                                              resizable: false,
                                              parent: true,
                                              draggable: false,
                                              x:
                                                item.locationXcoordinate *
                                                _vm.mapRate *
                                                _vm.mapObj.ratio,
                                              y:
                                                item.locationYcoordinate *
                                                _vm.mapRate *
                                                _vm.mapObj.ratio,
                                              w: 40,
                                              h: 40,
                                              grid: [20, 20],
                                            },
                                            on: {
                                              activated: function ($event) {
                                                return _vm.onMarkedInfo(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("c-map-marker", {
                                              attrs: {
                                                isSelected:
                                                  item.idx ===
                                                  _vm.mapObj.activeMarked,
                                              },
                                            }),
                                            _c(
                                              "q-menu",
                                              {
                                                ref: "markedproxy" + item.idx,
                                                refInFor: true,
                                                attrs: {
                                                  "content-class":
                                                    "mainMapMenuLayer",
                                                  anchor: "top right",
                                                  self: "top left",
                                                },
                                              },
                                              _vm._l(
                                                _vm.mapObj.selectedWorkPermit
                                                  .workPermits,
                                                function (workPermit, idx) {
                                                  return _c(
                                                    "q-card",
                                                    {
                                                      key: idx,
                                                      class: [
                                                        "main-work-permit-card main-work-permit-card-cursor",
                                                        _vm.setStepClass(
                                                          workPermit.swpStepCd
                                                        ),
                                                      ],
                                                      attrs: {
                                                        flat: "",
                                                        bordered: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openWorkPermitPop(
                                                            workPermit
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-item",
                                                        {
                                                          staticClass:
                                                            "main-work-permit-card-section main-work-permit-card-item",
                                                        },
                                                        [
                                                          _c(
                                                            "q-item-section",
                                                            {
                                                              attrs: {
                                                                avatar: "",
                                                              },
                                                            },
                                                            [
                                                              _c("q-badge", {
                                                                attrs: {
                                                                  rounded: "",
                                                                  color:
                                                                    _vm.setStepColor(
                                                                      workPermit.swpStepCd
                                                                    ),
                                                                  label:
                                                                    workPermit.swpStepName,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "q-item-section",
                                                            [
                                                              _c(
                                                                "q-item-label",
                                                                _vm._l(
                                                                  _vm.setTypeIcons(
                                                                    workPermit.sopWorkTypeCd
                                                                  ),
                                                                  function (
                                                                    icon,
                                                                    iconIdx
                                                                  ) {
                                                                    return _c(
                                                                      "q-icon",
                                                                      {
                                                                        key: iconIdx,
                                                                        staticClass:
                                                                          "text-red",
                                                                        attrs: {
                                                                          name: icon,
                                                                          size: "25px",
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "q-card-section",
                                                        {
                                                          staticClass:
                                                            "main-work-permit-card-section2",
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                workPermit.workSummary
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "q-card-section",
                                                        {
                                                          staticClass:
                                                            "main-work-permit-card-section1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                workPermit.workTime
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      })
                                    : _vm._e(),
                                ],
                              ],
                              2
                            )
                          }),
                          1
                        )
                      : _c("el-empty", {
                          attrs: {
                            "image-size": 435,
                            description: "사업장에 등록된 지도가 없습니다.",
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "accTypeIcons" }, [
      _c("div", { staticClass: "bg-red lawcheckIcon2" }),
      _vm._v("미진행   "),
      _c("div", { staticClass: "bg-yellow lawcheckIcon2" }),
      _vm._v("진행   "),
      _c("div", { staticClass: "bg-green lawcheckIcon2" }),
      _vm._v("완료 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }